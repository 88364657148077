<template>
	<div
		class="details-document-package"
		:class="{
			'container-layout': $screen.width > 576,
			'container-mobile': $screen.width <= 576
		}"
	>
		<!-- <h1 v-html="rendPageTitle" />  -->
		<b-form>
			<div>
				<custom-loading-planning :loading="isLoading" :label="FormMSG(129, 'Prepare data of location')" />
				<h1 v-if="$screen.width <= 567" class="main-page-title" :class="[{ 'is-pwa': $isPwa() }]">
					{{ mobileTitle }}
				</h1>
				<b-row>
					<b-col cols="12" class="p-0">
						<b-card no-body :class="{ 'card-border-blue-light': $screen.width > 567, 'bg-color-white pl-3 pr-3': $screen.width <= 567 }">
							<b-row v-if="$screen.width > 567" class="back-with-title d-flex align-items-center py-1 pl-0">
								<b-col cols="6">
									<h2 style="font-size: 1.5rem">{{ getTitle }}</h2>
								</b-col>
								<b-col cols="6" class="d-flex justify-content-end" style="margin-top: -8px">
									<b-button variant="custom-outline-gray" class="w-138-px" :disabled="isUpdtLocationLoading" @click="handleClickCancel">
										{{ FormMSG(658, 'Cancel') }}
									</b-button>
									<b-button variant="primary" class="w-138-px ml-3" :disabled="isUpdtLocationLoading" @click="submitLocation">
										<b-spinner v-if="isUpdtLocationLoading" small />
										{{ FormMSG(659, 'Save') }}
									</b-button>
									<button
										type="button"
										class="btn-transparent text-color-rhapsody-in-blue-2 ml-3"
										:disabled="isUpdtLocationLoading"
										@click="handleClickX"
									>
										<component :is="getLucideIcon('X')" :size="30" />
									</button>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col>
									<b-form-radio-group id="showOptionLocation" v-model="currentLocation.status" :options="statusOptions" plain />
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="12">
									<div class="card-collapsible px-3 mt-3">
										<!-- SECTION GENERAL AND PHOTOS -->
										<div v-if="$screen.width <= 576" class="divider-line mt-3 mb-3" style="margin-left: -15px; margin-right: -15px" />
										<b-row class="mt-3">
											<b-col sm="12" md="7" lg="7" xl="7">
												<h2 v-if="$screen.width <= 576" class="sub-title">{{ FormMSG(651, 'General') }}</h2>
												<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`">
													<legend
														v-if="$screen.width > 576"
														:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														class="text-color-rhapsody-in-blue-2"
													>
														{{ FormMSG(651, 'General') }}
													</legend>
													<div :class="{ 'pt-3 pb-3': $screen.width > 576 }">
														<b-row class="pb-3">
															<b-col sm="12" md="4" lg="4" xl="4">
																<b-form-group :label="FormMSG(8, 'Name')" label-for="location_name">
																	<b-form-input
																		id="location_name"
																		v-model="currentLocation.setName"
																		type="text"
																		:placeholder="FormMSG(9, 'Enter name...')"
																		autocomplete="off"
																	/>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="3" lg="3" xl="3">
																<b-form-group :label="this.FormMSG(255, 'Type 1')" label-for="optionsForTypeOne">
																	<b-form-select
																		id="optionsForTypeOne"
																		v-model="currentLocation.type1"
																		:options="typeOneOptions"
																	/>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="3" lg="3" xl="3">
																<b-form-group :label="this.FormMSG(254, 'Type 2')" label-for="optionsForTypeTwo">
																	<b-form-select
																		id="optionsForTypeTwo"
																		v-model="currentLocation.type"
																		:options="optionsForLocationType"
																	/>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="2" lg="2" xl="2">
																<b-form-group :label="FormMSG(256, 'Total area')" label-for="totalArea">
																	<b-input-group>
																		<b-form-input v-model="currentLocation.surface" />
																		<b-input-group-append>
																			<b-input-group-text class="bg-color-north-wind text-color-white">
																				{{ FormMSG(259, 'm2') }}
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col sm="12" md="4" lg="4" xl="4">
																<select-main-characteristic
																	:data-id="currentLocation.mainCharacteristic"
																	@select-main-characteristic:change="handleSelectMainCharacteristicChange"
																/>
															</b-col>
															<b-col sm="12" md="8" lg="8" xl="8">
																<b-form-group :label="FormMSG(289, 'Description')">
																	<b-form-textarea v-model="currentLocation.description" rows="3" max-rows="6" />
																</b-form-group>
															</b-col>
														</b-row>
													</div>
												</fieldset>
											</b-col>
											<b-col sm="12" md="5" lg="5" xl="5">
												<div
													v-if="$screen.width <= 576"
													class="divider-line mt-3 mb-3"
													style="margin-left: -15px; margin-right: -15px"
												/>
												<h2 v-if="$screen.width <= 576" class="sub-title">{{ FormMSG(650, 'Photos') }}</h2>
												<fieldset
													class="my-0 py-0 pb-0"
													:class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`"
													style="height: 100%"
												>
													<legend
														v-if="$screen.width > 576"
														:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														class="text-color-rhapsody-in-blue-2"
													>
														{{ FormMSG(650, 'Photos') }}
													</legend>
													<b-row>
														<b-col>
															<include-imgs
																:parent-id="currentLocation ? currentLocation.id : 0"
																:images="currentLocation ? currentLocation.images : []"
																carousel-mode
																@change="handleImagesChange"
																class="mt-4"
															/>
														</b-col>
													</b-row>
												</fieldset>
											</b-col>
										</b-row>

										<!-- SECTION LOCATION AND CONTACT -->
										<b-row class="mt-3">
											<b-col sm="12" md="7" lg="7" xl="7">
												<div
													v-if="$screen.width <= 576"
													class="divider-line mt-3 mb-3"
													style="margin-left: -15px; margin-right: -15px"
												/>
												<h2 v-if="$screen.width <= 576" class="sub-title pb-0 mb-0">{{ FormMSG(649, 'Location') }}</h2>
												<fieldset
													class="my-0 py-0 pb-0"
													:class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`"
													tyle="height: 100%"
												>
													<legend
														v-if="$screen.width > 576"
														:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														class="text-color-rhapsody-in-blue-2"
													>
														{{ FormMSG(649, 'Location') }}
													</legend>
													<div class="w-90 pt-3 pb-3">
														<b-row class="pb-3">
															<b-col cols="12">
																<b-form-group :label="FormMSG(458, 'Address')">
																	<MainAddressLocation
																		:params="mainLocationAddress"
																		:address-id="currentLocation[mainLocationAddress.name]"
																		@change="handleDynamicAddressChange"
																		@delete="handleDynamicAddressDelete"
																		@address-maplink-change="handleAddressMapLinkChange"
																	/>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col sm="12" md="5" lg="5" xl="5">
																<b-form-group :label="FormMSG(457, 'Document attachments')">
																	<b-input-group>
																		<b-form-input
																			:value="documentSelected.fileName"
																			class="br-none input-readonly"
																			disabled
																		/>
																		<b-input-group-append>
																			<b-input-group-text class="bg-color-white input-group-text-bg-white">
																				<button
																					type="button"
																					class="btn-transparent text-color-aqua-velvet"
																					@click="viewDocument"
																				>
																					<component :is="getLucideIcon('File')" :size="16" />
																				</button>
																				<b-dropdown
																					id="dropdown-header"
																					size="sm"
																					no-caret
																					dropleft
																					toggle-class="btn-transparent"
																				>
																					<template slot="button-content">
																						<component
																							:is="getLucideIcon('ChevronDown')"
																							:size="16"
																							fill="#06263E"
																						/>
																					</template>
																					<b-dropdown-item-button v-if="listDocuments.length === 0">
																						{{ FormMSG(547, 'No document found') }}
																					</b-dropdown-item-button>
																					<b-dropdown-item-button
																						v-for="(doc, index) in listDocuments"
																						:key="index"
																						aria-describedby="dropdown-header-label"
																						@click="handleClickDocument(doc)"
																					>
																						{{ doc.fileName }}
																					</b-dropdown-item-button>
																				</b-dropdown>
																				<button
																					type="button"
																					class="btn-transparent text-color-aqua-velvet"
																					@click="openDocumentModal"
																				>
																					<component :is="getLucideIcon('PlusCircle')" :size="16" />
																				</button>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</b-form-group>
															</b-col>
														</b-row>
													</div>
												</fieldset>
											</b-col>
											<b-col sm="12" md="5" lg="5" xl="5">
												<div
													v-if="$screen.width <= 576"
													class="divider-line mt-3 mb-3"
													style="margin-left: -15px; margin-right: -15px"
												/>
												<h2 v-if="$screen.width <= 576" class="sub-title">{{ FormMSG(629, 'Contact') }}</h2>
												<fieldset
													class="my-0 py-0 pb-0"
													:class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`"
													tyle="height: 100%"
												>
													<legend
														v-if="$screen.width > 576"
														:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														class="text-color-rhapsody-in-blue-2"
													>
														{{ FormMSG(629, 'Contact') }}
													</legend>
													<div :class="{ 'pt-3 pb-3': $screen.width > 576 }">
														<b-row :class="$screen.width <= 576 ? 'mb-2' : ''">
															<b-col cols="12">
																<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : ''}`">
																	<legend
																		v-if="$screen.width <= 576"
																		:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
																		class="text-color-rhapsody-in-blue-2"
																	>
																		{{ FormMSG(498, 'Supplier') }}
																	</legend>
																	<div :class="{ 'pt-1 pb-1': $screen.width <= 576 }">
																		<supplier-selector
																			:title="`${
																				!isOwnerSupplierUpdate
																					? FormMSG(250, 'Add supplier details')
																					: FormMSG(251, 'Update supplier details')
																			}`"
																			:label="$screen.width > 576 ? FormMSG(481, 'Supplier') : ''"
																			:placeholder="FormMSG(13, 'Filter by name')"
																			:addlabel="FormMSG(5442, 'New supplier')"
																			:update-label="FormMSG(40, 'Update')"
																			:show-map="false"
																			type="owner"
																			:supplier-id="ownerSupplierId"
																			@change="handleOwnerSupplierChange"
																			@supplier:unselected="onOwnerSupplierUnselected"
																			use-new-design
																			version="1.0"
																			class="mb-3"
																		/>
																	</div>
																</fieldset>

																<!-- </b-form-group> -->
															</b-col>
														</b-row>
														<b-row>
															<b-col v-if="currentLocation.owner.companyType !== 0" sm="12" md="3" lg="3" xl="3">
																<b-form-group :label="FormMSG(452, 'Contact name')">
																	<b-form-input v-model="currentLocation.owner.contactName" />
																</b-form-group>
															</b-col>
															<b-col sm="12" md="6" lg="6" xl="6">
																<b-form-group :label="FormMSG(400, 'Contact email')">
																	<b-form-input v-model="currentLocation.owner.email" />
																</b-form-group>
															</b-col>
															<b-col
																sm="12"
																:md="currentLocation.owner.companyType !== 0 ? 3 : 6"
																:lg="currentLocation.owner.companyType !== 0 ? 3 : 6"
																:xl="currentLocation.owner.companyType !== 0 ? 3 : 6"
															>
																<b-form-group :label="FormMSG(399, 'Contact phone')">
																	<b-form-input v-model="currentLocation.owner.phone" />
																</b-form-group>
															</b-col>
														</b-row>
													</div>
												</fieldset>
											</b-col>
										</b-row>

										<!-- SECTION FILMING AND SETS CONSUMPTION -->
										<b-row>
											<b-col sm="12" md="7" lg="7" xl="7">
												<b-row>
													<b-col cols="12">
														<div
															v-if="$screen.width <= 576"
															class="divider-line mt-3 mb-3"
															style="margin-left: -15px; margin-right: -15px"
														/>
														<h2 v-if="$screen.width <= 576" class="sub-title pb-0 mb-0">{{ FormMSG(602, 'Filming') }}</h2>
														<fieldset
															class="my-0 py-0 pb-0"
															:class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`"
															tyle="height: 100%"
														>
															<legend
																v-if="$screen.width > 576"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
																class="text-color-rhapsody-in-blue-2"
															>
																{{ FormMSG(602, 'Filming') }}
															</legend>
															<div class="pt-3 pb-5">
																<div>
																	<b-row>
																		<b-col sm="12" md="6" lg="6" xl="6">
																			<b-form-group :label="FormMSG(603, 'Filming area')" label-for="totalArea">
																				<b-input-group>
																					<b-form-input v-model="currentLocation.filmingArea" />
																					<b-input-group-append>
																						<b-input-group-text class="bg-color-north-wind text-color-white">
																							{{ FormMSG(259, 'm2') }}
																						</b-input-group-text>
																					</b-input-group-append>
																				</b-input-group>
																			</b-form-group>
																		</b-col>
																		<b-col sm="12" md="6" lg="6" xl="6">
																			<b-form-group :label="FormMSG(685, 'Number of days used')">
																				<b-form-input v-model="currentLocation.totNbOfDaysOccupation" />
																			</b-form-group>
																		</b-col>
																	</b-row>
																	<b-row>
																		<b-col v-if="isFilm()" sm="12" md="6" lg="6" xl="6">
																			<b-form-group :label="FormMSG(604, 'Location prep date')">
																				<b-form-input
																					v-model="currentLocation.getInDate"
																					type="date"
																					@change="handleChangeGetInDate"
																				/>
																			</b-form-group>
																		</b-col>
																		<b-col v-if="isFilm()" sm="12" md="6" lg="6" xl="6">
																			<b-form-group :label="FormMSG(607, 'Location wrap date')">
																				<b-form-input
																					v-model="currentLocation.getOutDate"
																					:min="currentLocation.getInDate"
																					type="date"
																					@change="handleChangeGetOutDate"
																				/>
																			</b-form-group>
																		</b-col>
																	</b-row>
																</div>

																<div class="mt-2">
																	<b-row>
																		<b-col>
																			<div class="clearfix d-flex fs-14 fw-700 w-100">
																				<div style="min-width: 9rem">
																					{{ FormMSG(574, 'Filming dates') }} <span class="dot-divider" />
																				</div>
																				<div class="d-flex w-100">
																					<b-badge pill class="badge-variant">
																						<span class="badge-notification">{{ filmingDatesNumber }}</span>
																					</b-badge>
																					<div class="w-94 ml-3">{{ FormMSG(575, 'filming days') }}</div>
																				</div>
																			</div>
																		</b-col>
																	</b-row>
																	<b-row class="mt-2">
																		<b-col sm="12" md="8" lg="8" xl="8">
																			<Calendar
																				:min-date="minDateValue"
																				:max-date="maxDateValue"
																				:attributes="attributes"
																				:from-page="dateToShow"
																				:locale="lang"
																				is-expanded
																				@dayclick="handleDayClick"
																			/>
																		</b-col>
																		<b-col v-if="$screen.width > 576" sm="12" md="4" lg="4" xl="4">
																			<div class="fs-14 fw-500 p-2 list-selected-dates-form-time-card">
																				<div v-if="shootingDatesFiltered.length > 0">
																					<perfect-scrollbar style="height: 255px">
																						<div v-for="(day, index) in shootingDatesFiltered" :key="index">
																							<div class="fs-14">
																								{{ day.date | formatDate('dddd MMMM DD, YYYY') }}
																							</div>
																						</div>
																					</perfect-scrollbar>
																				</div>
																				<div v-else>
																					<div class="text-center">
																						{{ FormMSG(531, 'No date selected') }}
																					</div>
																				</div>
																			</div>
																		</b-col>
																	</b-row>
																</div>

																<b-row class="mt-3">
																	<b-col>
																		<div class="d-inline-block fs-14 fw-700">
																			{{ FormMSG(613, 'Off-set locations') }}
																		</div>
																		<div class="d-inline-block fs-14 fw-400 text-color-rhapsody-in-blue-2">
																			{{ FormMSG(614, '(if different than location address)') }}
																		</div>
																	</b-col>
																</b-row>

																<offset-locations
																	:data-to-edit="currentLocation"
																	@offset-locations:change="handleOffsetLocationsChange"
																	@offset-locations:remove="handleOffsetLocationsRemove"
																/>
															</div>
														</fieldset>
													</b-col>
												</b-row>

												<!-- SECTION FILMING AND ENERGY CONSUMPTION -->
												<b-row>
													<b-col cols="12">
														<div
															v-if="$screen.width <= 576"
															class="divider-line mt-3 mb-3"
															style="margin-left: -15px; margin-right: -15px"
														/>
														<h2 v-if="$screen.width <= 576" class="sub-title pb-0 mb-0">
															{{ FormMSG(652, 'Energy consumption') }}
														</h2>
														<fieldset
															class="my-0 py-0 pb-0"
															:class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`"
															tyle="height: 100%"
														>
															<legend
																v-if="$screen.width > 576"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
																class="text-color-rhapsody-in-blue-2"
															>
																{{ FormMSG(652, 'Energy consumption') }}
															</legend>
															<div class="pt-3 pb-3">
																<b-row class="pb-3">
																	<b-col sm="12" md="3" lg="3" xl="3">
																		<b-form-group>
																			<label for="type" class="pb-2">
																				<div style="display: flex; align-items: center">
																					{{ FormMSG(631, 'Energy saving plan') }}&nbsp;
																					<info-tooltip>
																						{{
																							FormMSG(
																								643,
																								'Have you implemented the Ademe energy saving plan?  Information can be found here:'
																							)
																						}}
																						<a
																							target="blank"
																							href="https://agirpourlatransition.ademe.fr/entreprises/sobriete-energetique-entreprises"
																							>{{ FormMSG(644, 'Ademe wabsite') }}</a
																						>
																					</info-tooltip>
																				</div>
																			</label>
																			<v-select
																				v-model="currentLocation.energySavingPlan"
																				:options="energySavingOptions"
																				label="text"
																				:reduce="(option) => option.value"
																				:clearable="false"
																			/>
																		</b-form-group>
																	</b-col>
																</b-row>

																<view-consumption-energy
																	:location="currentLocation"
																	@view-consumption-energy:add-edit="handleClickeAddEditConsumption"
																/>
															</div>
														</fieldset>
													</b-col>
												</b-row>
											</b-col>
											<b-col sm="12" md="5" lg="5" xl="5">
												<fieldset
													class="my-0 py-0 pb-0"
													:class="`${
														$screen.width <= 576 ? 'card-inside bg-color-white border-ligth-starlight' : 'scheduler-border'
													}`"
													tyle="height: 100%"
												>
													<legend
														:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
														class="text-color-rhapsody-in-blue-2"
													>
														{{ FormMSG(619, 'Sets') }}
													</legend>
													<div class="pt-3 pb-5">
														<set-names :data-to-edit="currentLocation" @set-names:change="handleSetNamesChange" />
													</div>
												</fieldset>
											</b-col>
										</b-row>
									</div>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>

				<b-row v-if="$screen.width <= 567" class="footer-fixed">
					<b-col cols="6">
						<b-button
							variant="outline-primary"
							block
							style="font-size: 16px; font-weight: 700"
							:disabled="isUpdtLocationLoading"
							@click="handleClickCancel"
						>
							{{ FormMSG(85, 'Cancel') }}
						</b-button>
					</b-col>
					<b-col cols="6">
						<b-button variant="primary" block style="font-size: 16px; font-weight: 700" :disabled="isUpdtLocationLoading" @click="submitLocation">
							{{ FormMSG(86, 'Save') }}
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-form>
		<location-document-modal
			v-model="isOpenDocumentModal"
			:location-id="currentLocation ? +currentLocation.id : 0"
			@location-document:hidden="onHideDocumentModal"
		/>

		<location-entry-carbon-dialog
			:open="showLocationEntryCarbon"
			:location="currentLocation"
			:carbons="carbons"
			@location-entry-carbon:submit-success="handleLocationEntryCarbonSubmitSuccess"
			@location-entry-carbon:close="handleLocationEntryCarbonClose"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import gql from 'graphql-tag';
import { isNil, makeID, objReducer } from '@/shared/utils';
import VueScrollTo from 'vue-scrollto';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
// import Capture from "@/components/ExpenseService/Capture";
// import Capture from "@/components/CaptureModule/Main";
import SupplierSelector from '@/components/SupplierSelector';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import DynamicAddress from '@/components/LocationService/DynamicAddress';
import MobileViewsLocationDetailsItemAddressCard from '@/components/MobileViews/LocationDetails/ItemAddressItem';
import IncludeElect from '@/components/LocationService/_Elect';
import IncludeGas from '@/components/LocationService/_Gas';
import IncludeWater from '@/components/LocationService/_Water';
import IncludeImgs from '@/components/LocationService/_Imgs';
import IncludeMapPicture from '@/components/LocationService/_MapPicture';
import { store } from '@/store';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';
import LocationDocumentModal from '@/components/Modals/LocationDocumentModal';
import globalMixin from '@/mixins/global.mixin';
import InfoTooltip from '@/components/LocationService/InfoTooltip';
import _ from 'lodash';
import SelectMainCharacteristic from '@/components/LocationService/SelectMainCharacteristic';
import OffsetLocations from '@/components/LocationService/OffsetLocations';
import { getLocation, updateLocation } from '@/cruds/locations.crud';
import Generator from '@/components/LocationService/Generator';
import SetNames from '../../components/LocationService/SetNames';
import { Carousel, Slide } from 'vue-carousel';
import { getDocuments } from '@/cruds/image-child.crud';
import { getFileExtension } from '@/shared/helpers';
import moment from 'moment';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
import { updateSupplier, getSupplier } from '@/cruds/suppliers.crud';
import LocationEntryCarbonDialog from '@/modules/carbonclap/components/green/LocationEntryCarbonDialog.vue';
import ViewConsumptionEnergy from '../../components/LocationService/ViewConsumptionEnergy.vue';

Vue.use(VueScrollTo);

export default {
	name: 'LocationMainComponent',
	components: {
		ViewConsumptionEnergy,
		CustomLoadingPlanning,
		Calendar,
		Carousel,
		Slide,
		SetNames,
		Generator,
		// Capture,
		MainAddressLocation,
		DynamicAddress,
		SupplierSelector,
		MobileViewsLocationDetailsItemAddressCard,
		IncludeElect,
		IncludeGas,
		IncludeWater,
		IncludeMapPicture,
		IncludeImgs,
		Loading,
		GreenFilmDocs,
		LocationDocumentModal,
		InfoTooltip,
		SelectMainCharacteristic,
		OffsetLocations,
		PerfectScrollbar,
		LocationEntryCarbonDialog
	},
	mixins: [languageMessages, globalMixin],
	data() {
		return {
			erreur: {},
			warning: '',
			isSubmitActive: false,
			dateToShow: null,
			// isMapPictureModalOpen: false,
			isUpdtLocationLoading: false,
			locationID: 0,
			// locationPayload: {},
			payload: {},
			isAddressModalOpen: false,
			currAdressModalOpened: null,
			addressPayload: null,
			isFormOpen: true,
			elecSupplierName: '',
			refreshTokenCaptureItem: 0,
			isOpenDocumentModal: false,
			elDocMounted: false,
			greenFilmDocLength: 0,
			isOwnerSupplierUpdate: false,
			isElecSupplierUpdate: false,

			statusSelected: 0,
			dateFormatOptions: {
				day: 'numeric',
				month: 'numeric',
				year: 'numeric'
			},

			currentLocation: {
				getIndDate: new Date().toISOString(),
				getOutDate: new Date(moment().add(1, 'days')).toISOString(),
				owner: {
					name: '',
					email: '',
					phone: ''
				},
				totNbOfDaysOccupation: 0
			},
			locationOriginal: {},
			currentLocationId: 0,

			listDocuments: [],
			documentSelected: {},
			locationImages: [],

			shootingDates: [],
			shootingDatesOriginal: [],

			isLoading: false,
			showLocationEntryCarbon: false,
			carbons: [],
			generatorFuelType: 0
		};
	},

	computed: {
		...mapGetters({
			currLocation: 'locations/currLocation'
		}),

		mobileTitle() {
			if (this.currLocation.createdAt === this.currLocation.updatedAt) {
				return this.FormMSG(351, 'Add location');
			} else {
				return this.FormMSG(352, 'Edit location');
			}
		},

		filmingDatesNumber() {
			if (this.shootingDates.length > 0) {
				if (this.shootingDates.length < 10) {
					return '0' + this.shootingDates.length;
				}

				return this.shootingDates.length;
			}

			return 0;
		},

		shootingDatesFiltered() {
			return this.shootingDates.sort((a, b) => {
				if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
				if (new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
				return 0;
			});
		},

		minDateValue() {
			return new Date(moment(this.currentLocation.getInDate).format('YYYY-MM-DD')).toISOString();
		},

		maxDateValue() {
			return new Date(moment(this.currentLocation.getOutDate).format('YYYY-MM-DD')).toISOString();
		},

		dates() {
			return this.shootingDates.map((day) => day.date);
		},
		attributes() {
			return this.dates.map((date) => ({
				highlight: true,
				dates: date,
				content: 'red'
			}));
		},

		typeOneOptions() {
			return this.FormMenu(1356);
		},
		energySavingOptions() {
			return this.FormMenu(1359);
		},
		statusOptions() {
			return this.FormMenu(1358);
		},
		optionsForLocationType() {
			if (!store.isPme()) {
				return this.FormMenu(1026);
			}

			return this.FormMenu(1322);
		},
		locationPayload: {
			get() {
				return this.$store.getters['locations/currLocation'];
			},
			set(val) {
				return (this.payload = val);
			}
		},
		rendPageTitle() {
			if (isNil(this.currentLocation)) return '';
			const { setName } = this.currentLocation;
			return `Location: ${setName}`;
		},
		mainLocationAddress() {
			return {
				name: 'addressId5',
				title: this.getTitle,
				address: 'address5'
			};
		},
		getTitle() {
			return store.isPme() ? this.FormMSG(131, 'Office Details') : this.FormMSG(145, 'New Location Entry');
		},
		addressFiller() {
			const _t = this.FormMSG;
			if (store.isPme()) {
				return [
					{
						name: 'medicalAddressId',
						title: _t(23236, 'Nearest Hospital'),
						address: 'medicalAddress'
					},
					{
						name: 'crewParkAddressId',
						title: _t(310, 'Parking address'),
						address: 'crewParkAddress'
					}
				];
			} else {
				return [
					{
						name: 'makeUpAddressId',
						title: _t(23232, 'Make up address'),
						address: 'makeUpAddress'
					},
					{
						name: 'facilitiesAddressId',
						title: _t(23233, 'Facilities address'),
						address: 'facilitiesAddress'
					},
					{
						name: 'costumeAddressId',
						title: _t(23237, 'Costume address'),
						address: 'costumeAddress'
					},
					{
						name: 'greenRoomAddressId',
						title: _t(23234, 'Green room address'),
						address: 'greenRoomAddress'
					},
					{
						name: 'cateringAddressId',
						title: _t(23235, 'Catering address'),
						address: 'cateringAddress'
					},
					{
						name: 'medicalAddressId',
						title: _t(23236, 'Nearest Hospital'),
						address: 'medicalAddress'
					},
					{
						name: 'unitBaseAddressId',
						title: _t(23238, 'Unit Base address'),
						address: 'unitBaseAddress'
					},
					{
						name: 'techBaseAddressId',
						title: _t(23239, 'Tech Base address'),
						address: 'techBaseAddress'
					},
					{
						name: 'crewParkAddressId',
						title: _t(232310, 'Crew park address'),
						address: 'crewParkAddress'
					},
					{
						name: 'techParkAddressId',
						title: _t(232311, 'Tech park address'),
						address: 'techParkAddress'
					}
				];
			}
		},
		ownerSupplierId() {
			// current supplier id of edit data
			this.isOwnerSupplierUpdate = true;

			if (isNil(this.currentLocation) || parseInt(this.currentLocation.ownerSupplierId, 10) === 0) {
				this.isOwnerSupplierUpdate = false;

				return 0;
			}

			return this.currentLocation.ownerSupplierId;
		},
		elecSupplier() {
			// current supplier id of edit data
			this.isElecSupplierUpdate = true;

			if (isNil(this.currentLocation) || parseInt(this.currentLocation.elecSupplier, 10) === 0) {
				this.isElecSupplierUpdate = false;

				return 0;
			}

			return this.currentLocation.elecSupplier;
		}
	},
	async created() {
		if (_.isNil(this.$route.params.id)) return;
		this.currentLocationId = parseInt(this.$route.params.id);
		await this.getLocation();
		await this.getDocumentsForLocation();
	},
	async mounted() {
		// if (isNil(this.currentLocation)) await this.goToLocationList();
		// this.prepareDocumentSelected();
		// this.reloadDocumentList(this.currentLocation.id);
		// await this.getDocumentsForLocation();
	},
	methods: {
		async handleLocationEntryCarbonSubmitSuccess({ carbons }) {
			this.currentLocation.carbon = carbons;

			await this.getLocation(true);
		},
		handleLocationEntryCarbonClose() {
			this.carbons = [];
			this.showLocationEntryCarbon = false;
		},
		handleClickeAddEditConsumption() {
			this.carbons = this.currentLocation.carbon;
			this.showLocationEntryCarbon = true;
		},
		isFilm() {
			return store.isFilm();
		},
		...mapActions({
			updateLocation: 'locations/updateLocation',
			getProjectLocationList: 'locations/getProjectLocationList',
			destroyCurrentLocation: 'locations/destroyCurrentLocation'
			// getLocation: 'locations/getLocation'
		}),

		handleChangeGetInDate(payload) {
			const getInDateToTime = new Date(payload).getTime();
			if (getInDateToTime > new Date(this.currentLocation.getOutDate).getTime()) {
				this.currentLocation.getOutDate = this.currentLocation.getInDate;
			}
			this.dateToShow = {
				month: parseInt(moment(payload).format('MM')),
				year: parseInt(moment(payload).format('YYYY'))
			};
			this.shootingDates = this.shootingDates.filter((option) => new Date(option.date).getTime() >= getInDateToTime);
		},

		handleChangeGetOutDate(payload) {
			const getOutDateToTime = new Date(payload).getTime();

			this.shootingDates = this.shootingDates.filter((option) => new Date(option.date).getTime() <= getOutDateToTime);
		},

		handleDayClick(payload) {
			const idx = this.shootingDates.findIndex((option) => option.date === moment(new Date(payload.date)).format('YYYY-MM-DD') + 'T00:00:00Z');

			if (idx >= 0) {
				this.shootingDates.splice(idx, 1);
			} else {
				this.shootingDates.push({
					date: moment(new Date(payload.date)).format('YYYY-MM-DD') + 'T00:00:00Z'
				});
			}
		},

		handleClickX() {
			this.$router.go(-1);
		},
		handleClickCancel() {
			if (this.$screen.width > 576) {
				const action = async () => {
					this.currentLocation = this.locationOriginal;
					this.shootingDates = this.shootingDatesOriginal;

					await this.submitLocation(true);

					this.handleClickX();
				};

				this.confirmModal(action, this.FormMSG(396, 'Your change will be lost, are you sure ?'));
			} else {
				this.handleClickX();
			}
		},
		handleClickDocument(document) {
			this.documentSelected = document;
			this.currentLocation.documentAttachmentXid = document.xid;
		},
		prepareDocumentSelected() {
			const documentAttachement = this.currentLocation.documentAttachment;
			this.documentSelected = {
				xid: documentAttachement.xid,
				ext: documentAttachement.ext,
				fileName: documentAttachement.originalFileName
			};
		},
		viewDocument() {
			const src = `${process.env.VUE_APP_GQL}/images/${this.documentSelected.xid}`;

			this.$previewImages({
				images: [
					{
						...this.documentSelected,
						src,
						xid: this.documentSelected.xid,
						ext: getFileExtension(this.documentSelected.fileName)
					}
				],
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		async getDocumentsForLocation() {
			this.listDocuments = await getDocuments(this.currentLocationId, 'location', 'documents');

			if (this.currentLocation.documentAttachmentXid !== '') {
				this.prepareDocumentSelected();
			}
		},
		async handleSetNamesChange(payload) {
			this.currentLocation = {
				...this.currentLocation,
				...payload
			};
		},
		async handleOffsetLocationsRemove(payload) {
			this.currentLocation = await updateLocation(this.currentLocationId, {
				...payload,
				carbon: {
					...this.currentLocation.carbon,
					carbonId: +this.currentLocation.carbon.id
				}
			});
			this.initDate();
		},
		async handleOffsetLocationsChange(payload) {
			this.currentLocation = await updateLocation(this.currentLocationId, {
				...payload,
				carbon: {
					...this.currentLocation.carbon,
					carbonId: +this.currentLocation.carbon.id
				}
			});
			this.initDate();
		},
		async getLocation(justTakeCarbonField = false) {
			const result = await getLocation(this.currentLocationId);

			if (!justTakeCarbonField) {
				this.currentLocation = result;
				this.currentLocation.totNbOfDaysOccupation = parseFloat(result.totNbOfDaysOccupation).toFixed(2);
				this.initDate();
			} else {
				this.currentLocation.consumptionElectricityTotal = result.consumptionElectricityTotal;
				this.currentLocation.consumptionGasTotal = result.consumptionGasTotal;
				this.currentLocation.consumptionWaterTotal = result.consumptionWaterTotal;
				this.currentLocation.volumeTotalDieselUsed = result.volumeTotalDieselUsed;
				this.currentLocation.volumeTotalGasUsed = result.volumeTotalGasUsed;
				this.currentLocation.volumeTotalGasolineUlUsed = result.volumeTotalGasolineUlUsed;
				this.currentLocation.volumeTotalHydrogenUsed = result.volumeTotalHydrogenUsed;
				this.currentLocation.carbon = result.carbon;
			}

			this.locationImages = this.currentLocation.images;
			this.locationOriginal = _.cloneDeep(this.currentLocation);
			this.shootingDates = this.currentLocation.locationDates.map((option) => ({
				date: option.date
			}));
			this.shootingDatesOriginal = _.cloneDeep(this.shootingDates);
		},
		initDate() {
			this.currentLocation.getInDate = moment(new Date(this.currentLocation.getInDate)).format('YYYY-MM-DD');
			this.currentLocation.shootingStartDate = moment(new Date(this.currentLocation.shootingStartDate)).format('YYYY-MM-DD');
			this.currentLocation.shootingEndDate = moment(new Date(this.currentLocation.shootingEndDate)).format('YYYY-MM-DD');
			this.currentLocation.getOutDate = moment(new Date(this.currentLocation.getOutDate)).format('YYYY-MM-DD');
			this.dateToShow = {
				month: parseInt(moment(this.currentLocation.getInDate).format('MM')),
				year: parseInt(moment(this.currentLocation.getInDate).format('YYYY'))
			};
		},
		handleSelectMainCharacteristicChange(payload) {
			this.currentLocation.mainCharacteristic = payload;
		},
		handleOpenNewWindow() {
			const currLink = this.currentLocation.googleMapLink;
			window.open(
				currLink !== '' ? currLink : 'https://www.google.com',
				this.FormMSG(213421, 'Google map link'),
				'height=670,width=992,modal=yes,alwaysRaised=yes'
			);
		},
		/**
		 * @param {String} name
		 */
		rendID(name) {
			return `_${name}_${makeID(5)}`;
		},
		handleIncludeChange(item) {
			if (isNil(item)) return;
			this.currentLocation = Object.assign(this.currentLocation, item);
		},
		async handleSaveIncludeMeterElem({ key, img }) {
			const up = this.quickSaveLocationElement;
			await up(key, img);
		},
		/**
		 * @param {Array} imgs
		 */
		async handleImagesChange(imgs) {
			const up = this.quickSaveLocationElement;
			const images = [...imgs, ...this.currentLocation.images];
			await up('images', images);
			this.currentLocation.images = images;
		},
		/**
		 * @param {Object} {id} - supplier
		 */
		async handleElecSupplierChange({ id }) {
			this.isElecSupplierUpdate = false;

			if (isNil(id) || this.currentLocation.elecSupplier === id) return;

			this.isElecSupplierUpdate = true;
			const up = this.quickSaveLocationElement;
			await up('elecSupplier', parseInt(id));
		},
		/**
		 * @param {Object} {id} - ownerSupplier
		 */
		async handleOwnerSupplierChange({ id, contactName, email, phone, companyType }) {
			this.isOwnerSupplierUpdate = false;

			if (isNil(id) || this.currentLocation.ownerSupplierId === id) return;

			this.isOwnerSupplierUpdate = true;
			if (this.currentLocation.owner) {
				this.currentLocation.owner.contactName = contactName;
				this.currentLocation.owner.email = email;
				this.currentLocation.owner.phone = phone;
				this.currentLocation.owner.companyType = companyType;
			}

			const up = this.quickSaveLocationElement;
			await up('ownerSupplierId', parseInt(id));
		},
		async handleMapPictureChange({ key, img }) {
			const up = this.quickSaveLocationElement;
			await up(key, img);
			this.getProjectLocationList();
		},
		async handleDynamicAddressChange(item) {
			const up = this.quickSaveLocationElement;
			await up(item.key, parseInt(item.id));
			this.currentLocation.addressId5 = +item.id;
			this.getProjectLocationList();
		},
		async handleDynamicAddressDelete(key) {
			const up = this.quickSaveLocationElement;
			await up(key, 0);
			this.getProjectLocationList();
		},
		/**
		 * @param {String} url
		 */
		async handleAddressMapLinkChange(url) {
			if (isNil(url) || url === '') return;
			this.currentLocation.googleMapLink = url;
			const up = this.quickSaveLocationElement;
			await up('googleMapLink', url);
		},
		async quickSaveLocationElement(key, value) {
			const locationID = this.currentLocation.id;
			await this.$apollo.mutate({
				mutation: gql`
					mutation ($LocationId: ID!, $UpdatedLocation: LocationInput!) {
						UpdLocation(LocationId: $LocationId, UpdatedLocation: $UpdatedLocation) {
							id
							images
							elecMeterStartPict
						}
					}
				`,
				variables: {
					LocationId: parseInt(locationID, 10),
					UpdatedLocation: {
						[key]: value,
						carbon: {
							...this.currentLocation.carbon,
							carbonId: +this.currentLocation.carbon.id
						}
					}
				}
			});
		},
		/**
		 * @param {Array} keys
		 * @param {Object} location
		 * @return {Object}
		 */
		setUpDate(keys, location) {
			const extraDate = 'T00:00:00Z';
			let l = location;
			keys.forEach((k) => {
				//    if (!isNil(location[k]) && !location[k].includes(extraDate))
				if (!isNil(location[k]) && !location[k].includes('T')) l[k] = location[k] + extraDate;
			});
			return l;
		},
		async submitLocation(reset = false) {
			if (!_.isBoolean(reset)) reset = false;

			this.isUpdtLocationLoading = true;

			let location = await this.setUpDate(['getInDate', 'getOutDate', 'shootingStartDate', 'shootingEndDate'], this.currentLocation);
			location.carbon.carbonId = +this.currentLocation.carbon.id;
			location.surface = parseFloat(this.currentLocation.surface);
			location.elecMeterStart = isNaN(parseFloat(this.currentLocation.elecMeterStart)) ? 0 : parseFloat(this.currentLocation.elecMeterStart);
			location.elecMeterEnd = isNaN(parseFloat(this.currentLocation.elecMeterEnd)) ? 0 : parseFloat(this.currentLocation.elecMeterEnd);
			location.gasMeterStart = isNaN(parseFloat(this.currentLocation.gasMeterStart)) ? 0 : parseFloat(this.currentLocation.gasMeterStart);
			location.gasMeterEnd = isNaN(parseFloat(this.currentLocation.gasMeterEnd)) ? 0 : parseFloat(this.currentLocation.gasMeterEnd);
			location.waterMeterStart = isNaN(parseFloat(this.currentLocation.waterMeterStart)) ? 0 : parseFloat(this.currentLocation.waterMeterStart);
			location.waterMeterEnd = isNaN(parseFloat(this.currentLocation.waterMeterEnd)) ? 0 : parseFloat(this.currentLocation.waterMeterEnd);
			location.generatorVolumeUsed = isNaN(parseFloat(this.currentLocation.generatorVolumeUsed))
				? 0
				: parseFloat(this.currentLocation.generatorVolumeUsed);
			location.filmingArea = parseFloat(this.currentLocation.filmingArea);
			location.totNbOfDaysOccupation = parseFloat(this.currentLocation.totNbOfDaysOccupation);

			const supplierInfo = {
				contactName: this.currentLocation.owner.contactName,
				email: this.currentLocation.owner.email,
				phone: this.currentLocation.owner.phone
			};
      
			let _shootingDates = [];
			if (this.shootingDates.length !== 0) {
				_shootingDates = [...this.shootingDates].map((item) => ({ ...item, display: true }));
			}

			await updateLocation(this.currentLocationId, {
				...objReducer(
					[
						'projectId',
						'description',
						'mapPicture',
						'googleMapLink',
						'setName',
						'surface',
						'selected',

						'getInDate',
						'getOutDate',
						'shootingStartDate',
						'shootingEndDate',
						'totNbOfDaysOccupation',

						'elecMeterStart',
						'elecMeterEnd',
						'elecMeterStartPict',
						'elecMeterEndPict',
						'elecSupplier',

						'gasMeterEnd',
						'gasMeterEndPict',
						'gasMeterStart',
						'gasMeterStartPict',
						'gasSupplier',

						'waterMeterEnd',
						'waterMeterEndPict',
						'waterMeterStart',
						'waterMeterStartPict',
						'waterSupplier',

						'generatorVolumeUsed',
						'generatorFuelType',
						'generatorPict',

						'type',
						'type1',
						'status',

						'setName1',
						'setName2',
						'setName3',

						'addressId1',
						'addressId2',
						'addressId3',
						'addressId4',
						'addressId5',
						'addressId6',
						'addressId7',
						'addressId8',
						'addressId9',
						'addressId10',
						'addressId11',
						'addressId12',
						'addressId13',
						'addressId14',

						'mainCharacteristic',
						'documentAttachmentXid',
						'filmingArea',
						'energySavingPlan',
						'paidByProduction',
						'carbon'
					],
					location
				),
				locationDates: _shootingDates
			})
				.then(async (result) => {
					this.getLocation();
					// this.currentLocation = result;
					// this.currentLocation.images = this.locationOriginal.images;
					this.initDate();
					let msgToast = this.FormMSG(312, 'Location saved successfully');
					await this.updateInfoSupplier(supplierInfo);
					if (reset === true) {
						msgToast = this.FormMSG(356, 'Change is canceled');
					}
					this.createToastForMobile(this.FormMSG(311, 'Success'), msgToast, '');
					if (reset === false) {
						setTimeout(() => {
							this.handleClickX();
						}, 500);
					}
				})
				.finally(() => {
					this.isUpdtLocationLoading = false;
				});
			//  this.goToLocationList();
		},
		async updateInfoSupplier(currentLocation) {
			if (parseInt(this.currentLocation.ownerSupplierId, 10) > 0) {
				const supplier = await getSupplier(parseInt(this.currentLocation.ownerSupplierId, 10));

				await updateSupplier({
					id: parseInt(this.currentLocation.ownerSupplierId, 10),
					supplier: {
						contactName: currentLocation.contactName,
						email: currentLocation.email,
						phone: currentLocation.phone
					},
					address: _.omit(supplier.address, ['id'])
				});
			}

			this.currentLocation.owner.contactName = currentLocation.contactName;
			this.currentLocation.owner.email = currentLocation.email;
			this.currentLocation.owner.phone = currentLocation.phone;
		},
		greenFilmDocsMounted(payload) {
			this.elDocMounted = true;
			this.greenFilmDocLength = payload;
		},
		reloadDocumentList(locationId) {
			// this.$refs.cardDocumentList.reloadData(locationId);
		},
		openDocumentModal() {
			this.isOpenDocumentModal = true;
		},
		async onHideDocumentModal(payload) {
			this.isOpenDocumentModal = false;
			// do reload table documents in card
			// this.reloadDocumentList(payload);
			await this.getDocumentsForLocation();
		},
		onOwnerSupplierUnselected() {
			this.isOwnerSupplierUpdate = false;
		},
		onElecSupplierUnselected() {
			this.isElecSupplierUpdate = false;
		}
	}
};
</script>

<style lang="scss">
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}
</style>
